import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

const element = document.getElementById('root');
if (!element) throw new Error('Root error');

const root = createRoot(element);
root.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
