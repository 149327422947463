import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// Jen
import { APICore } from '../api/apiCore';

// lazy load all the views
//home
const Home = React.lazy(() => import('../pages/home/home'));

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// extra pages
const Error404 = React.lazy(() => import('../pages/error/Error404'));

//ForgetPassword
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));

//Validate Code
const ValidateCode = React.lazy(() => import('../pages/auth/ValidateCode'));

//Users
const UserTables = React.lazy(() => import('../pages/user/UserList'));

//Operations
const Operations = React.lazy(() => import('../pages/operations/menu'));

//Operations
const Client = React.lazy(() => import('../pages/operations/Client/ClientList'));

const BoardActivities = React.lazy(() => import('../pages/boardActivities/board'));

const HoursList = React.lazy(() => import('../pages/hoursWorked/hoursList'));

//dashboards
const HoursWorkedAll = React.lazy(() => import('../pages/dashboards/HoursWorked.tsx/allHoursWorked'));
const HoursUserCharts = React.lazy(() => import('../pages/dashboards/HoursUserCharts/hoursUserCharts'));
const ProjectDaysCharts = React.lazy(() => import('../pages/dashboards/projectDaysCharts/projectDaysCharts'));
const MarginProjectCharts = React.lazy(() => import('../pages/dashboards/marginProjectCharts/marginProject'));
const UserAssignedActivities = React.lazy(() => import('../pages/dashboards/userAssignedActivities/list'));
const FinishedProjects = React.lazy(() => import('../pages/dashboards/graphicFinishedProjects/finishedProjects'));
const HoursAvailable = React.lazy(() => import('../pages/dashboards/hoursProjects/hoursProjects'));


//Administrable
const costHour = React.lazy(() => import('../pages/Admin/costHour/costHour'));
const categoryHour = React.lazy(() => import('../pages/Admin/categoriesHours/categoryHour'));
const banner = React.lazy(() => import('../pages/Admin/Banner/banner'));
const sessionHistory = React.lazy(() => import('../pages/Admin/sessionHistory/sessionHistory'));


//Empresas 
const Company = React.lazy(() => import('../pages/Company/List'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

//home
const homeRoutes: RoutesProps = {
    path: '/home',
    name: 'Home',
    icon: 'ri-table-line',
    roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb',
        '3cdd5bd3-18ee-11ed-92af-a4b1c1c093eb', '3cdeb95c-18ee-11ed-92af-a4b1c1c093eb',
        'adde0c1f-75f2-11ed-9251-0a95e092a52b', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
    header: 'Navigation',
    route: PrivateRoute,
    component: Home
};


// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/auth/ValidateCode',
        name: 'Validate Code',
        component: ValidateCode,
        route: Route,
    }, {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];

//Tablero
const BoardRoutes: RoutesProps = {
    path: '/tablero',
    name: 'tablero',
    icon: 'ri-table-line',
    roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb',
        '3cdd5bd3-18ee-11ed-92af-a4b1c1c093eb', '3cdeb95c-18ee-11ed-92af-a4b1c1c093eb',
        'adde0c1f-75f2-11ed-9251-0a95e092a52b', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
    header: 'Navigation',
    route: PrivateRoute,
    component: BoardActivities
};

const HoursWorkedRoutes: RoutesProps = {
    path: '/hoursWorked',
    name: 'hoursWorked',
    icon: 'ri-calendar-2-line',
    roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb',
        '3cdd5bd3-18ee-11ed-92af-a4b1c1c093eb', '3cdeb95c-18ee-11ed-92af-a4b1c1c093eb',
        'adde0c1f-75f2-11ed-9251-0a95e092a52b', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
    header: 'Navigation',
    route: PrivateRoute,
    component: HoursList
}

const DashboardsRoutes: RoutesProps = {
    path: '/dashboards',
    name: 'dashboards',
    icon: 'ri-bar-chart-line',
    roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb',
        '3cdd5bd3-18ee-11ed-92af-a4b1c1c093eb', '3cdeb95c-18ee-11ed-92af-a4b1c1c093eb',
        'adde0c1f-75f2-11ed-9251-0a95e092a52b', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
    header: 'Navigation',
    route: PrivateRoute,
    children: [
        {
            path: '/dashboards/allHoursWorked',
            name: 'allHoursWorked',
            component: HoursWorkedAll,
            route: PrivateRoute
        },
        {
            path: '/dashboards/hoursUserCharts',
            name: 'hoursUserCharts',
            component: HoursUserCharts,
            route: PrivateRoute
        },

        {
            path: '/dashboards/userAssignedActivities',
            name: 'userAssignedActivities',
            component: UserAssignedActivities,
            route: PrivateRoute
        },
        {
            path: '/dashboards/projectDays',
            name: 'projectDays',
            component: ProjectDaysCharts,
            route: PrivateRoute
        },
        {
            path: '/dashboards/finishedProjects',
            name: 'finishedProjects',
            component: FinishedProjects,
            route: PrivateRoute
        },
        {
            path: '/dashboards/marginProjects',
            name: 'marginProjects',
            component: MarginProjectCharts,
            route: PrivateRoute
        },
        {
            path: '/dashboards/hoursAvailable',
            name: 'hoursAvailable',
            component: HoursAvailable,
            route: PrivateRoute
        }
    ]
}

const AdminRoutes: RoutesProps = {
    path: '/admin',
    name: 'admin',
    icon: 'ri-message-2-line',
    roles: ['3cd45c1f-18ee-11ed-92af-a4b1c1c093eb', 'fab978c5-c5bc-49f9-8d58-446262be8551'], //no sirve lo pasa
    header: 'Navigation',
    route: PrivateRoute,
    children: [
        {
            path: '/admin/costHour',
            name: 'Costo por Hora',
            component: costHour,
            route: PrivateRoute
        },
        {
            path: '/admin/categoryHour',
            name: 'Categoria de Horas',
            component: categoryHour,
            route: PrivateRoute
        },
        {
            path: '/admin/banner',
            name: 'Banner',
            component: banner,
            route: PrivateRoute
        },
        {
            path: '/admin/sessionHistory',
            name: 'Historial de Sesiones',
            component: sessionHistory,
            route: PrivateRoute
        }
    ]
}

//User
const userRoutes: RoutesProps = {
    path: '/user',
    name: 'Users',
    icon: 'fe-user',
    roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
    header: 'Navigation',
    route: PrivateRoute,
    children: [
        {
            path: '/user/list',
            name: 'ListUser',
            component: UserTables,
            route: PrivateRoute,
            roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
        }
    ]
};

//Operations
const operationRoutes: RoutesProps = {
    path: '/operation',
    name: 'Operationes',
    roles: ['3cdc5998-18ee-11ed-92af-a4b1c1c093eb', '3cd45c1f-18ee-11ed-92af-a4b1c1c093eb',
        'adde0c1f-75f2-11ed-9251-0a95e092a52b', 'fab978c5-c5bc-49f9-8d58-446262be8551'],
    icon: 'ri-folders-line',
    header: 'Navigation',
    route: PrivateRoute,
    children: [
        {
            path: '/operation/index',
            name: 'Menu',
            component: Operations,
            route: PrivateRoute
        },
        {
            path: '/operation/list',
            name: 'Menu',
            component: Client,
            route: PrivateRoute
        }
    ]
};

//Tablero
const companyRoutes: RoutesProps = {
    path: '/empresa',
    name: 'empresa',
    icon: 'ri-table-line',
    roles: ['fab978c5-c5bc-49f9-8d58-446262be8551'],
    header: 'Navigation',
    route: PrivateRoute,
    component: Company
};

// public routes
const otherPublicRoutes = [
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route
    }
];

const appRoutes = [
    homeRoutes,
    userRoutes,
    operationRoutes,
    BoardRoutes,
    HoursWorkedRoutes,
    DashboardsRoutes,
    AdminRoutes,
    companyRoutes
];


// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    const api = new APICore();
    let flatRoutes: RoutesProps[] = [];

    const loggedInUser = api.getLoggedInUser();

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        if (loggedInUser && loggedInUser.idRol !== undefined) {

            if (item?.roles !== undefined) { //todos deberian tener rol pero por aquellas

                if (item.roles?.indexOf(loggedInUser.idRol) !== -1) {
                    flatRoutes.push(item);
                    if (typeof item.children !== 'undefined') {
                        flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
                    }
                }

            } else {
                flatRoutes.push(item);
            }
        } else {
            //si no tiene sesion deberia sacarlo 
            flatRoutes.push(item);
            if (typeof item.children !== 'undefined') {
                flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
            }
        }
    }

    );
    return flatRoutes;
};

// // All routes
const authProtectedRoutes = [rootRoute, ...appRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes, flattenRoutes };
