import { jwtDecode } from "jwt-decode";
import axios from "axios";

import config from "./../config";
import { decrypt, encriptarDatos } from './encrypted';

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    console.log("-+-+-+-+ OJO +-+-+- ", error);
    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
     // window.location.href = '/auth/login'; // access-denied
     console.log("encuentra error");
    } else {
      console.log("-+-+-+-+ OJO +-+-+- ", error);
      switch (error.response.status) {
        case 401:
          message = "Invalid credentials";
          break;
        case 403:
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }
      return Promise.reject(message);
    }
    //return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (axios.isAxiosError(error)) {
//       // Log the error details
//       console.error("Axios error:", error.response ? error.response.data : error.message);
//     } else {
//       // Handle unexpected errors
//       console.error("Unexpected error:", error);
//     }
//     // Return a rejected promise with the error message
//     return Promise.reject(error);
//   }
// );

const AUTH_SESSION_KEY = "userData";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

const getToken = () => {
  const userData = sessionStorage.getItem("userData");

  // Si userData existe, parsear el objeto
  const user = userData ? JSON.parse(userData) : null;

  // Obtener el token del objeto user
  return user ? user.token : null;
};

class APICore {
  /**
   * Fetches data from given url
   */

  get = async (url: string, params: any) => {
    try {
      const newUrl = `${url}${params}`;
      const response = await axios.get<any>(newUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      });

      return decrypt(response.data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  };

  get2 = async (url: string, params: any) => {
    axios.defaults.baseURL = config.API_URL;
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = await axios.get(`${url}?${queryString}`, params);
    } else {
      response = await axios.get(`${url}`, params);
    }
    return response;
  };

  getTicket = async (url: string, params: any) => {
    axios.defaults.baseURL = config.API_URL_TICKET;
    try {
      const { data } = await axios.get<any>(`${url}${params}`);
      axios.defaults.baseURL = config.API_URL;
      return decrypt(data.data);
    } catch (error) {
      axios.defaults.baseURL = config.API_URL;
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  };

  list = async (url: string) => {
    try {

      const response = await axios.get<any>(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });
      return decrypt(response.data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    const response= axios.post(url, data);
    console.log("esssssss: ", response);
     return response;
  };

  /*Status 200 aqui */
  create2 = async (url: string, info: any) => {
    try {
      const dataEncript = await encriptarDatos(info);
      const { data } = await axios.post<any>(url, dataEncript, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${token}`
        },
      });
      return decrypt(data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("error message: ", error.message);
        // 👇️ error: AxiosError<any, any>
        return error.message;
      } else {
        console.error("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  postTicket = async (url: string, info: any) => {
    axios.defaults.baseURL = config.API_URL_TICKET;
    try {
      const response = await axios.post<any>(url, info, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      axios.defaults.baseURL = config.API_URL;
      return response;
    } catch (error) {
      axios.defaults.baseURL = config.API_URL;
      if (axios.isAxiosError(error)) {
        console.error("error message: ", error.message);
        return error.message;
      } else {
        console.error("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  getAllTicket = async (url: string, params: any) => {
    axios.defaults.baseURL = config.API_URL_TICKET;
    try {
      let response;
      if (params) {
        var queryString = params
          ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
          : "";
        response = axios.get(`${url}?${queryString}`, params);
      } else {
        response = axios.get(`${url}`, params);
      }
      axios.defaults.baseURL = config.API_URL;
      return response;
    } catch (error) {
      axios.defaults.baseURL = config.API_URL;
      if (axios.isAxiosError(error)) {
        console.error("error message: ", error.message);
        return error.message;
      } else {
        console.error("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  updatePatch2 = async (url: string, info: any) => {
    try {
      const dataEncript = await encriptarDatos(info);
      const { data } = await axios.patch<any>(url, dataEncript, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${token}`
        },
      });

      return decrypt(data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("error message: ", error.message);
        // 👇️ error: AxiosError<any, any>
        return error.message;
      } else {
        console.error("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  updatePatchTicket = async (url: string, info: any) => {
    axios.defaults.baseURL = config.API_URL_TICKET;
    try {
      const dataEncript = await encriptarDatos(info);
      const { data } = await axios.patch<any>(url, dataEncript, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      axios.defaults.baseURL = config.API_URL;
      return decrypt(data.data);
    } catch (error) {
      axios.defaults.baseURL = config.API_URL;
      if (axios.isAxiosError(error)) {
        console.error("error message: ", error.message);
        return error.message;
      } else {
        console.error("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  updateActivityNameTicket = async (url: string, info: any) => {
    axios.defaults.baseURL = config.API_URL_TICKET;
    try {
      const dataEncript = await encriptarDatos(info);
      const { data } = await axios.patch<any>(url, dataEncript, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${token}`
        },
      });
      axios.defaults.baseURL = config.API_URL;
      return decrypt(data.data);
    } catch (error) {
      axios.defaults.baseURL = config.API_URL;
      if (axios.isAxiosError(error)) {
        console.error("error message: ", error.message);
        return error.message;
      } else {
        console.error("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete2 = (url: string) => {
    return axios.delete(url);
  };

  delete = async (url: string, params: any) => {
    try {
      const newUrl = `${url}${params}`;
      const { data } = await axios.delete<any>(newUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${token}`
        },
      });
      return data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        return "An unexpected error occurred";
      }
    }
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: any) => {
    if (session)
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
