import CryptoJS from "crypto-js";

async function encriptarDatos(json: Object) {
    const ciphertext = await encrypt(JSON.stringify(json), 'ALF84NFBD3HFGLG720CMBEW3vkftyw9');
    return { datos: ciphertext };
}

function encrypt(text: string, secretKey: string) {
    return new Promise((resolve, reject) => {
        try {
            const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
            resolve(ciphertext);
        } catch (e) {
            console.error(e);
            reject("");
        }
    });
};

const decrypt = (jsonString: string): any => {
    const bytes = CryptoJS.AES.decrypt(jsonString, 'ALF84NFBD3HFGLG720CMBEW3vkftyw9');
    const json = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return json;
};

export { encriptarDatos, decrypt };